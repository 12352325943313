.title {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 30px;
    font-family: Open Sans, Helvetica, Arial, sans-serif;
}
.day {
    display: block;
    font-size: 64px;
    line-height: 60px;
    font-weight: 100;
}

.month {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

.read-more {
    text-align: center;
}
.excerpt-read-more {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: .3em;
    font-weight: 700;
    line-height: 35px;
    margin: 0 auto;
    padding: 0px 30px;
    border-top: 1px solid #f4f4f3;
    border-bottom: 1px solid #f4f4f3;
    color: #7e828b;
    position: relative;
    display: inline-block;
    -webkit-transition: all 0.2s ease-in-out;

    transition: all 0.2s ease-in-out;
}