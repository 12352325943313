html, body, #root, #root>div {
    height: 100%
  }
.teste {
    background-color: black!important;
}

.ImagemBackgorund {
    background-image: url('./backhome.png');
    background-size: contain;
}

.loginContainer {
    background-color:rgba(255, 255, 255, 0.95);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
}

.loginContainer form {
    grid-row-start: 2;
}