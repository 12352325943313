
.infogeral   {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .16);
    background-color: #fff;
    border-radius: 5px;
}

#PLD , th, td {
    border: 1px solid black;
}

#PLD th {
    text-align: center;
    background-color: #385682;
    color: white;
}

#PLD tr:nth-child(even) {
    background-color: #bcd7ee;
}