.loginInput input{
    border-color:#294670;
    border-radius: 5px;
}

.loginSubmit {
    background-color: #385682!important;
    border: none;
    height: 48px;
    width: 35%!important;
}

.loginSubmit:disabled {
    background-color: #385682!important;
}

.loginForgot a {
    font-size:16px;
    font-family: 'Roboto'
}