.sidebar .nav-link{
    color: #fff;
    text-align: left;
    height: 5em;
    vertical-align: middle;
    display: table-cell;
}

.sidebar a {
    border-bottom:solid 1px;
    border-color: rgba(255, 255, 255, 0.26);
    height: 10%!important;
}

.sidebar svg {
    margin-right: 22px;
}
.sidebar a:hover .sidebar-off{
    width: 6px;
}

.sidebar-active {
    width:10px;
    height:64%;
    background-color:#FF8C00;
    left: 5px;
    z-index: 1;
    position:relative;
    margin-top: 7%;
    -webkit-transition: width 0.5s; /* Safari prior 6.1 */
    transition: width 0.5s;
}

.sidebar-off {
    width: 0px;
    height:64%;
    background-color:#FF8C00;
    left: 5px;
    z-index: 1;
    position:relative;
    margin-top: 7%;
    -webkit-transition: width 0.1s; /* Safari prior 6.1 */
    transition: width 0.1s;
}

.sidebar-menu {
    height:100px;
}

@media (max-width: 768px) { 
    .side-parent {
        display: none!important;
    }
 }

 @media (max-height: 600px) {
    .sidebar a {
    height: 14%!important;
    }
}