#grafico {
    overflow: auto;
    overflow-x: hidden;
}
.subgraphIcon svg {
    margin-left: 10px;
}
/* width */
#grafico::-webkit-scrollbar {
    width: 9px;
    background-color: #AEAEAE;
  }
/* Handle */
#grafico::-webkit-scrollbar-thumb {
    background: #EC7A2E; 
    border-radius: 5px;
}
.graphPanel {
    display:grid;
    background-color:#fff!important;
    box-shadow: 1px 1px 6px 6px rgba(0, 0, 0, .16);
    border-radius: 5px;
    grid-template-rows: 10% 65% 25%;
}

.subgraphIcon {
    color:#294670!important;
    font-size: 14px!important;
    background-color: #fff0!important;
    border-radius: 5px!important;
    border: solid 1px !important;
    border-color: #294670 !important;
    height: 50px!important;
    min-width: 180px !important;
    margin: 5px!important;
    margin-top: 1%!important;
    margin-bottom: 1%!important;
}

.subgraphIcon:focus{
    background-color: #2947706b!important;
    outline: none;
}

.subgraphIcon:active {
    background-color: #294670!important;
    outline: none;
}

.MuiButton-root {
    text-transform: none!important;
}

