.dashboard {
    padding-left: 0;
}

.navbuttons {
    text-align: center;
    margin: 0 auto;
}

.navbuttons  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

.navbuttons  ul a {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .16);
    background-color: #fff;
    border-radius: 5px;
    padding: 20px!important;
    height: 100%;
    padding: 0!important;
    text-transform: capitalize!important;
    font-size: 18px!important;
}

.navbuttons  ul a:hover {
    background-color: #EC7A2E;
}

.navbuttons  ul a hr {
    width: 100%;
}
@media (max-width: 525px) { 
    .navbuttons  ul {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
    }
}
