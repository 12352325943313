.graphIcon {
    background-color: #f5f5f5!important;
    border:0;
    border-radius: 5px;
    width: 100%;
    outline: #f5f5f5!important;
    padding: 0!important;
}

.graphIcon:active li {
    background-color: #EC7A2E!important;
}

.graphIcon footer  {
    font-size: 15px;
    font-family: 'Roboto';
    color:#272727;
    text-transform: capitalize;
}

.graphIcon hr  {
    margin-top: 6px;
    margin-bottom: 6px;
}

.graphFooter {
    background-color: #f5f5f5;
    height: 250px!important;
    justify-content: center;
}

.graphButton {
    width: 100px;
    height: 50px;;
}

.svg {
    padding-top: 10px!important;
}

@media (max-width: 1170px) {
    .graphIcon footer  {
        font-size: 10px;
        padding-bottom: 5px;
        font-family: 'Roboto';
        color:#272727;
        text-transform: capitalize;
    }
}

@media (max-height: 716px) {
    .graphIcon footer  {
        font-size: 10px;
        padding-bottom: 5px;
        font-family: 'Roboto';
        color:#272727;
        text-transform: capitalize;
    }
}