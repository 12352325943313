.MuiBadge-badge {
    margin-top: 25px;
    margin-right:5px;
}

.logo-container {
    background-color:#385682;
}

.logo {
    width: 20%;
    text-align: center;
    margin-right: 5%;
}

.pesquisa-container {
    width:61%;
}

.pesquisa {
    background-color:rgb(72, 104, 150);
    border:0;
}

.pesquisa::placeholder {
    color: #fff;
}
.nav-icons button:focus{
    outline: none;
}
#basic-navbar-nav {
    border-radius: 5px;
}
#basic-navbar-nav a.nav-link {
    border-bottom: solid#fff 1px;
}
#basic-navbar-nav a.nav-link a {
    color:#fff;
}

.user-info {
    display: flex;
    margin-left:10px; 
    color:#fff;
}
.user-info .avatar {
    margin-left: 15px;
    width: 60px;
    height: 60px;
}

@media (min-width: 892px) { 
    #basic-navbar-nav{
        display: none!important;
    }
}
@media (max-width: 991px) { 
    .nav-icons {
        display: none!important;
    }
}

.avatar img {
    object-fit: fill!important;
}