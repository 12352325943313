.painel {
    background-color:#fff!important;
    box-shadow: 1px 1px 6px 6px rgba(0, 0, 0, .16);
    border-radius: 5px;
    overflow: auto;
}

.changeButton {
    outline: none!important;
}

/* width */
#notification::-webkit-scrollbar {
    width: 9px;
    background-color: #AEAEAE;
  }
/* Handle */
#notification::-webkit-scrollbar-thumb {
    background: #EC7A2E; 
    border-radius: 5px;
}