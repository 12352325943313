.painel {
    background-color:#fff!important;
    box-shadow: 1px 1px 6px 6px rgba(0, 0, 0, .16);
    border-radius: 5px;
    height: 100%!important;
}
.painel-footer {
    height: 10%!important;
}

.changeButton {
    outline: none!important;
}

/* width */
#pdfview::-webkit-scrollbar {
    width: 9px;
    background-color: #AEAEAE;
  }
/* Handle */
#pdfview::-webkit-scrollbar-thumb {
    background: #EC7A2E; 
    border-radius: 5px;
}

.noenergy {
    padding-left: 35%;
    padding-top:25% ;
}

.loaderenergy {
    padding-left: 50%;
    padding-top:25% ;
}