#navbuttons-graficos {
    text-align: center;
    margin: auto;
    overflow:auto;
    overflow-y:auto;
    overflow-x:hidden;
    padding:20px;
}

#navbuttons-graficos ul li:active {
    background-color: #EC7A2E!important; 
}
#navbuttons-graficos ul {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    height: 95%;
}

#navbuttons-graficos  ul li {
    background-color: #fff;
    border-radius:5px;
    border: 1px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .16);
    width: 70%!important;
}


/* width */
#navbuttons-graficos::-webkit-scrollbar {
    width: 9px!important;
    background-color: #AEAEAE!important;
  }
/* Handle */
#navbuttons-graficos::-webkit-scrollbar-thumb {
    background: #EC7A2E!important; 
    border-radius: 5px!important;
}